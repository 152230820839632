import React from 'react';

import LayoutEs from '../components/LayoutEs';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Contact from '../components/Contact';
import SEO from '../components/Seo';
import Intro from '../components/B2B/Intro';
import Projects from '../components/Proyectos';
import Calendly from '../components/Calendly';
import ScrollToTop from '../components/ScrollToTop';
import { navigate } from 'gatsby';

class SolucionesEcommerceB2B extends React.Component {
  render() {
    return (
      <LayoutEs>
        <Header />
        <SEO
          title="Soluciones Ecommerce B2B para mayoristas, fabricantes y distribuidores"
          description="Agencia especializada en proyectos ecommerce B2B. Descubre como Magento y BigCommerce pueden ayudar a tus clientes y equipo de ventas a ser más eficientes y rentables."
          canonicalUrl="https://www.bekome.digital/soluciones-ecommerce-b2b/"
        />

        <header className="masthead secondary ecommerce-b2b d-flex">
          <div className="container text-center my-auto">
            <h1 className="mb-1 text-primary">Soluciones B2B</h1>
            <h2 className="mb-4">
              para mayoristas, fabricantes y distribuidores
            </h2>
            <p className='h2 mb-5'>
              ¿Te imaginas poder gestionar tu negocio desde una sola plataforma?<br />
              Convierte tu ecommerce en el <span className="text-primary">centro de operaciones</span> de tu empresa y controla desde allí todas las tareas comerciales,
              tanto online como offline.
            </p>
            <button className='btn btn-primary-green btn-lg' onClick={() => {navigate("/contacto/")}}>¿Hablamos?</button>
          </div>
          <div className="overlay"></div>
        </header>

        <Intro />

        <Projects />

        <Calendly />
        
        <div className='container-fluid bg-grey'>
          <h3 className="font-size__l text-secondary text-center pt-5">
            ¿Te gustaría conseguir todo esto con tu plataforma B2B?
          </h3>
          <h4 className='text-secondary text-center pb-4 mb-0'>Cuéntanos tus dudas y resolvámoslas juntos.</h4>
        </div>

        <Contact />

        <Footer />
        <ScrollToTop />
      </LayoutEs>
    );
  }
}

export default SolucionesEcommerceB2B;
