import React from 'react';

export default function Intro() {
  return (
    <section className="intro-section" id="start">
      <div className="container-fluid bg-grey">
        <div className="row">
          <div className='col-12 text-center'>
          <h2 className="font-size__xl mb-5 mt-5 text-secondary">
              Agilizamos los procesos de compra
            </h2>
          </div>
          <div className="col-12 col-lg-6 block-padding__left">
            <p className='font-size__m'>
             Seamos realistas, vender a otras empresas no se limita a la transacción de intercambiar bienes por dinero.
            </p>
            <p className='font-size__m'>
              Una venta B2B implica mucho más que eso: relaciones con clientes, presupuestos, 
              condiciones de pago personalizadas, compras recurrentes, gestión de stocks, estrategias comerciales...
            </p>
            <p className='font-size__m'>
              En definitiva, un sinfín de tareas que acaban desviando la atención de aquello que realmente genera ingresos: 
              la venta.
            </p>
            <p className='font-size__m'>
            Afortunadamente, existen plataformas Ecommerce que contemplan las necesidades del mercado B2B y ofrecen funcionalidades 
            diversas para que el proceso de compra resulte más eficiente tanto para el vendedor como para el comprador.
            </p>
            <p className='font-size__m font-weight__700 text-primary'>
              <u>Y eso se traduce en una mayor conversión.</u>
            </p>
          </div>
          <div className="col-12 col-lg-6 bg-ecommerce-b2b block-padding__right">
           
          </div>
        </div>
      </div>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-12 col-sm-6 content-section bg-primary order-0">
            <h3 className="font-size__xl text-center mb-5">
              ¿Cómo beneficia una solucion B2B a tu empresa?
            </h3>
            <div className="row">
              <div className="col-9 mx-auto">
                <p className='font-size__m'>
                  <ul className='mb-5 text-left list-unstyled text-secondary'>
                    <li><i class="fa-solid fa-check text-secondary"></i> Omnicanalidad</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Integración con el ERP</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Automatización de procesos</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Generación de presupuestos</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Mayor control sobre el stock. Múltiples almacenes</li>
                    <li><i class="fa-solid fa-check text-secondary"></i> Escalabilidad y rendimiento</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 epo-bg order-1 pb-0 pb-lg-5">
            <h3 className="font-size__xl text-center text-primary mb-5">
            ¿Cómo beneficia una solucion B2B a tus clientes?
            </h3>
            <div className="row text-center">
              <div className="col-9 mx-auto">
                <p className="font-size__m">
                  <ul className='mb-5 text-left list-unstyled'>
                    <li><i class="fa-solid fa-check text-primary"></i> Realizar pedidos de forma rápida y sencilla</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Múltiples formas de pago</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Tarifas personalizadas para cada cliente</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Fidelización</li>
                    <li><i class="fa-solid fa-check text-primary"></i> Información de producto y pedidos en tiempo real</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
